.banner-container {
    width: 100%;
  }
  
  .banner-slide-image {
    width: 100%;
    height: 400px;
    object-fit: contain;
  }
  
  .banner-slide-content {
    text-align: center;
    padding: 20px;
  }
  .banner-slide-content-mobile h3 {
    font-size: 20px;
    font-weight: 600;
  }
  .banner-slide-content-mobile p {
    text-align: justify;
  }
  
  .banner-buy-now-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .banner-container {
    width: 100%;
  }
  
  .banner-slide-image-mobile {
    width: 100%;
    height: auto;
    
  }
  
  .banner-slide-content-mobile {
    text-align: center;
    padding: 20px;
  }
  
  .banner-buy-now-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    .banner-slide-mobile {
      /* display: flex; */
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  
    .banner-slide-image-mobile {
      height: auto; 
      
    }
  
    .banner-slide-content-mobile {
      width: 50%;
      padding: 20px;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1400px) {
    .banner-slide-image-mobile {
      /* width: 50%; */
      height: 480px;
      /* object-fit: cover; */
    }
  }
  
.category-product-list-container {
    margin: 20px;
  }
  
  .category-product-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto; /* Enable horizontal scrolling */
  }
  
  .category-product-item {
    margin: 10px;
    width: 120px; /* Set a fixed width */
    
  }
  
  .category-product-info {
    display: block;
  }
  
  .category-product-category {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 600;
    text-align:center;

  }
  
  .category-product-image {
    min-width: 120px !important;
    height: 120px;
    border-radius: 50%; /* Make the image circular */
    border: 1px solid gray;
    object-fit: cover;
  }
  .category-product-image img{
    width: 100%;
    height: 100%;
  }

  
.category-has-scrollbar {
  padding-bottom: 5px;
}

.category-has-scrollbar::-webkit-scrollbar {
  width: 12px; /* for vertical scroll */
  height: 12px; /* for horizontal scroll */
}

.category-has-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
  border: 3px solid var(--white);
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.category-has-scrollbar:hover::-webkit-scrollbar-thumb {
  background: hsl(0, 0%, 90%);
}

.category-has-scrollbar::-webkit-scrollbar-thumb:hover {
  background: hsl(0, 0%, 80%);
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .category-product-image {
        min-width: 80px !important;
        height: 80px;
        border-radius: 50%; /* Make the image circular */
        border: 1px solid gray;
        object-fit: cover;
     
      }
      .category-product-image img{
        width: 100%;
        height: 100%;
      }
      .category-product-item{
        margin-bottom: -10px;
        text-align: center;
      }
      
  }

  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    /* No specific changes */
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    /* No specific changes */
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    /* No specific changes */
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    /* No specific changes */
  }